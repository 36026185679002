
import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"


const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;



const AdStyle = css`

.hr-line {
    border-top: 1px solid black;
}

/* .ad-wrapper::before {
    content: "Advertisement";
    display: block;
    height: 1.75rem;
    font-size: .75rem;
    color: #677075;
    text-align: center;
} */

`;

const composedStyle = css`
    ${tailwindStyle};
    ${AdStyle};
`;


interface IAdProps {
    className: string;
    dataAdSlot: string,
    dataAdFormat: string,
    dataAdLayout: string
}




export const BigQueryBlogAdComponent: React.FC<IAdProps> = ({className, children, dataAdSlot, dataAdFormat,
    dataAdLayout}) => {

    const ref = useRef<HTMLModElement>(null);
    const [showLine, setShowLine] = useState(false);

    // https://stackoverflow.com/questions/46035999/how-to-embed-google-adsense-in-react-js
    // https://www.google.com/adsense/new/u/0/pub-7213012588969666/
    // https://github.com/ProdigyAPI/prodigy-wiki/blob/c3256b2632ffceae66bc555d7b6339c3413114d5/src/components/GoogleAdsenseAd.tsx

    useEffect(() => {
        // If there is nothing currently displayed
        if (ref.current != null){
            ref.current.className = "adsbygoogle"
            
            // @ts-expect-error
            if (window.adsbygoogle == null) window.adsbygoogle = [];
            // @ts-expect-error
            window.adsbygoogle.push({});

        }

    }, []);


    return (
        <div className={className} >
            <div className={composedStyle}>
                <GlobalStyles />
                <div className="ad-or-line">
                    {/* (ref.current != null) &&  */}
                    { <p tw="block text-sm text-[#677075]  opacity-70 text-center">Advertisement</p>}
                </div>
                {/* https://support.google.com/adsense/thread/13006961/google-ads-iframes-have-no-title-a11y-issue?hl=en */}
                {/* aria-hidden set to true because the ad itself does not give the iframe a title  */}

                {/* <div className="ad-wrapper" tw="" aria-hidden="true"> */}
                {/* Google has strict rules around wrapping around the ins tag */}
                {/* data-full-width-responsive="true" */}
                
                <ins 
                    style={{display: 'block', textAlign: 'center'}}
                    data-ad-client="ca-pub-7213012588969666"
                    data-ad-layout={dataAdLayout}
                    data-ad-slot={dataAdSlot}
                    data-ad-format={dataAdFormat}
                    ref={ref}
                    />
                
            </div>
        </div>
        
    )

}