import { css } from '@emotion/css'


// Used in explainable ai overview

// Blue information box ("callout") in Tutorial BigQuery
// yellowbox used in Tutorial BigQuery

const calloutemotionstyle = css`

section.container .callout p {
  width: 96% !important;
}

.callout p {
    
    padding: 1rem 1rem 1rem 3rem;
    background: #e8f0fe;
    border: 1px solid #3740ff;
    color: #272eb5;
    position: relative;
    /* max-width: 40rem; */
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-display: swap;
    margin-bottom: 0px;
  }

  .callout p:first-child:before {
    content: "";
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23272eb5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M14 9.5c0-.825.675-1.5 1.5-1.5h1c.825 0 1.5.675 1.5 1.5v1c0 .825-.675 1.5-1.5 1.5h-1c-.825 0-1.5-.675-1.5-1.5v-1zM20 24h-8v-2h2v-6h-2v-2h6v8h2z"/><path d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm0 29C8.82 29 3 23.18 3 16S8.82 3 16 3s13 5.82 13 13-5.82 13-13 13z"/></svg>');
    background-size: cover;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    position: absolute;
    left: 1rem;
  }

  .callout br {
    line-height: 150%;
  }


  /* End Callout */

  /* Begin Yellowbox */
  .yellowbox {
    background: #f3e744;
    border: 1px solid #927803;
    font-weight: 700;
  }

  section.container .yellowbox {
    width: 92% !important;
    /* margin-right: 20vw !important; */
  }

`;

export default calloutemotionstyle;