const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2022-11-15-00",
    created_at: "2022-11-15T00:00:00.000-00:00",
    updated_at: "2022-11-15T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/blog/2022/11/15/tutorial-bigquery/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
    },
    title: "Data Analyst Tutorial – Learn Google BigQuery",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Increase your chances of getting a data analyst job by learning Google BigQuery!",
    image: {
        url: "https://whiteowleducation.com/images/blog/2022/11/15/card-main-image.png",
        description: "Donations related to the 2022 election. Analysis was created using Google BigQuery."
    },
    internal_featured_image: {
        pathname: "images/blog/2022/11/15/main-page-image.png",
        description: "Donations related to the 2022 election. Analysis was created using Google BigQuery."
    },
    keywords: tags[0],
    featured: "yes",
    read_time_minutes : "10",
    visibility: "public"
}